

* {
    font-family: 'Noto Sans', sans-serif;
}

body {
display: flex;
flex-direction: column;
justify-content: center;
margin: 0;
background-color: rgb(15, 15,15);
}

.home{
    height: 100vh;
}

.main {
    background-color: rgb(15, 15,15);
}
.gradient {
    background: linear-gradient(to right, #21D1EA, #52E481);
    -webkit-background-clip: text;
    color: transparent;
}
h1 {
    font-size: 5rem;
    font-weight: 800;
}
h4 {
    font-size: 1.5rem;
    color: #ffffff96;
    font-weight: 500;

}
@media (max-width: 1200px) {
    h1 {
        font-size: 4rem; /* tamanho ajustado para telas médias */
    }
    h4{
        font-size: 1.2rem;
    }
    .navbar-nav .nav-link {
        width: 100%;
        text-align: center;
      }
      .btn-doc {
        width: 100%;
      }


}

@media (max-width: 900px) {
    h1 {
        font-size: 3rem; /* tamanho ajustado para telas pequenas */
    }
    h4{
        font-size: 1rem;
    }
    .navbar-nav .nav-link {
        width: 100%;
        text-align: center;
      }
      .btn-doc {
        width: 100%;
      }

}

@media (max-width: 600px) {
    h1 {
        font-size: 2.5rem; 
    }
    h4{
        font-size: 0.8rem;
    }
    .navbar-nav .nav-link {
        width: 100%;
        text-align: center;
      }
      .btn-doc {
        width: 100%;
      }
}



.navbar{
    margin-top: 15px;
}
.btncolor{
    background-image: linear-gradient(to right, #21D1EA, #52E481);
    color: #1C3D28;
    border: none;
    padding: 10px 20px;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btnbranco{
    border:2px solid;
    border-color: #ffffffa4;
    color: #ffffffa4;
    padding: 10px 20px;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.btnbranco:hover{
    background-color: #ffffff00;
    color: #52E481;
    padding: 10px 20px;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.btn2 {
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 15px;
}
.btn2:hover {
color: white;
}

svg {
    margin-bottom: 40px;
}

.navbar-burger {
cursor: pointer;
}


.navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
        }
.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.nav-item{
    padding: 5px;
}

i{
    padding: 2px;
}

#link{
    font-size: 1.1rem;
    color: #ffffffde;
    font-weight: 600;
}

#link:hover{
    color: #52E481;
}

#active{
    color: #52E481;
    font-size: 1.1rem;
    font-weight: 600;
}

.col-lg-4{
    padding: 10px;
}

.col-lg-12{
    padding: 10px;
}


.col:hover{
    border: 2px solid #21d3ea54;
}


.boticon{
    margin-top: 40px;
    margin-bottom: 20px;
}

.botborder{
    padding: 10px;
    border: 2px solid #ffffff54;
    border-radius: 10px;
}
.botborder:hover{
    border: 2px solid #21d3ea54;
}

.borderadicionais{
    padding: 10px;
    border: 2px solid #ffffff54;
    border-radius: 10px;
}
.borderadicionais:hover{
    border: 2px solid #21d3ea54;
}
.nome{
    margin-bottom: -2px;
    padding-bottom: 0;
}
.bold{
    font-weight: 700;
}
.destaque{
    color: #ffffff42;
}

.btn-invite{
    width: 100%;
}

hr{
    margin-top: 5px;
    border-top: 2px solid #ffffff42;
}

.end{
    margin-bottom: 15px;
}
.end2{
    margin-bottom: 10px;
}
.end4{
    margin-bottom: 30px;
}
.funcao{
    background-color: #ffffff13;
    margin: 5px 0px;
    padding: 8px 0px;
    border-radius: 5px;
}

.no-margin{
    padding: 0;
}


.f-texto{
    font-weight: 550;
    color: #ffffffd5;
}

.titulo-area{
    margin: 10px;
    margin-left: 0;
}

.titulo{
    margin-top: 5px;
    font-weight: 600;
    text-align: center;
}

.sutitulo{
    margin-top: -10px;
    color: #ffffffa4;
    font-weight: 500;
    text-align: center;
}
.info{
    color: #ffffffa4;
    font-weight: 500;
    text-align: justify;
    font-weight: 500;
    font-size: 1.2rem;
}
.navbar-top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100; /* Isso garante que a navbar sempre apareça no topo de outros elementos */
  }

  .bots{
    margin-top: 20px;
  }

  .adicionais{
    padding: 10px;
  }

  p{
    font-weight: 500;
    font-size: 1.2rem;
  }

  .col-lg-3{
    margin-left: -5px;
  }

  .col-lg-9{
    padding-right: 25px;
  }

  .plano{
    text-align: center;
  }
.titulo-plano{
    font-weight: 700;
    font-size: 1.2rem;
    color: #ffffffa4;
    margin-top: -10px;
}
  .preço{
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 0;
  }

  .requer{
    color: #ffffffa4;
    font-weight: bold;
    font-size: 0.9rem;
  }
.branco{
    color:white
}

.copy{
    color: #ffffffa4;
    font-size: 0.8rem;
    text-align: center;
    margin-top: -5px;
    font-weight: bold;
}